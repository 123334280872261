/* eslint-disable react/jsx-key */
import { Datagrid, DateField, Filter, List, Loading, SelectInput, ShowButton, TextField, TextInput } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { UserListActions } from './ui/actions';
import { isRequired } from '../../../validators';
import { useHttpClient } from '../../../hooks/useHttpClient';
import { AdminModelResponseDto } from '../../../api';
import { API_URL } from '../../../constants';

const UsersFilters = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="id" label={LABELS.userId} alwaysOn />
      <TextInput source="username" label={LABELS.username} alwaysOn />
      <TextInput source="phone" label={LABELS.phone} alwaysOn />
      <SelectInput
        source="withDevices"
        label={LABELS.withDevices}
        alwaysOn
        choices={[
          { id: 'true', name: 'Да' },
          { id: 'false', name: 'Нет' },
        ]}
        defaultValue=""
      />
      <SelectInput
        source="modelId"
        label={LABELS.model}
        inputProps={{ maxLength: 127 }}
        helperText="Уникальное, не более 127 знаков"
        choices={props.deviceModelOptions}
        optionText="name"
        fullWidth
        defaultValue=""
        alwaysOn
      />
    </Filter>
  );
};

export const UserList = (props: { title?: string }) => {
  const { data, isLoading } = useHttpClient<AdminModelResponseDto>(API_URL.getModels);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const deviceModelOptions = data.data.map((model: any) => ({
    id: model.id,
    name: model.name,
  }));

  return (
    <List {...props} actions={<UserListActions />} filters={<UsersFilters deviceModelOptions={deviceModelOptions} />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label={LABELS.userId} sortable={false} />
        <TextField source="username" label={LABELS.username} sortable={true} />
        <TextField source="phone" label={LABELS.phone} sortable={false} />
        <DateField source="dateOfBirth" label={LABELS.dateOfBirth} sortable={false} />
        <TextField source="role" label={LABELS.userRole} sortable={false} />
        <DateField source="createdAt" label={LABELS.registratedAt} sortable={true} />
        <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
