export const LABELS = {
  factoryId: 'ID производителя',
  rMin: 'R_id Min, Ohm',
  rMax: 'R_id Max, Ohm',
  flavor: 'Flavor',
  formula: 'Formula',
  comment: 'Comment',
  id: 'ID',
  deviceId: 'ID Устройства',
  cartridgeId: 'ID Картриджа',
  username: 'Имя Пользователя',
  name: 'Имя',
  modelName: 'Имя модели',
  phone: 'Телефон',
  email: 'Email',
  alias: 'Алиас',
  mac: 'MAC Адрес',
  userId: 'ID Пользователя',
  hardwareId: 'Hardware ID',
  hardwareVersion: 'Версия оборудования',
  firmwareId: 'Firmware ID',
  modelId: 'ID Модели',
  version: 'Версия',
  downloadFileKey: 'Ссылка',
  dateOfBirth: 'Дата рождения',
  userRole: 'Роль пользователя',
  createdAt: 'Дата создания',
  registratedAt: 'Дата регистрации',
  deletedAt: 'Дата удаления',
  batchDate: 'Дата партии',
  updatedAt: 'Дата обновления',
  addedAt: 'Дата добавления',
  password: 'Пароль',
  isPhoneConfirmed: 'Телефон подтвержден',
  isDeleted: 'Удален',
  isBanned: 'Забанен',
  hardware: 'Hardware',
  firmware: 'Версия прошивки',
  user: 'Пользователь',
  model: 'Модель',
  featureToggleId: 'Toggle ID',
  featureToggleName: 'Название',
  isFeatureToggleEnabled: 'Активен',
  downloadFirmwareButton: 'Скачать прошивку',
  notifyOtaUsers: 'Уведомить пользователей',
  serialNumber: 'Серийный номер',
  os: 'Операционная система',
  fullName: 'ФИО',
  passportSeriesAndNumber: 'Паспорт',
  registrationAddress: 'Адрес',
  issuedBy: 'Выдан',
  issuedDate: 'Дата выдачи',
  nonDisclosureAgreement: 'NDA',
  dataProcessingAndPrivacyConsent: 'Конф.',
  documentPhotoUrl: 'Фото',
  iterator: '№',
  delete: 'Удалить',
  withDevices: 'С девайсами',
};
